.image_section_start {
  display: flex;
  margin-top: 30px;
}
.Image_Input {
  opacity: 1;
  position: relative;
}

.from_Top {
  margin-top: 30px;
}
