.itinerary_main {
  display: grid;
  grid-template-columns: 0.2fr 0.2fr;
  margin-top: 20px;
}
.itineraray_data {
  display: grid;
  grid-template-columns: 0.2fr 0.4fr;
  margin-top: 20px;
}

.incExc_main {
  display: grid;
  margin-top: 20px;
  grid-template-columns: 0.2fr 0.4fr 0.2fr;
}
.for_top {
  margin-top: 20px;
}
.incExc_Data {
  align-items: center;
  gap: 20px;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 0.2fr 0.4fr 0.2fr;
}
.plus_icon_incexc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Create_Package_main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  padding-right: 20px;
}
.multiple_images {
  display: flex;
  margin-top: 30px;
}
.multiple_images img {
  margin-right: 20px;
}
.create_package {
  margin-top: 20px;
}

.create_package_field {
  width: 100%;
  height: 40px;
  padding-left: 10px;
}
