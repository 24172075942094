.PopHeader {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 20px;
  color: var(--sacondary-color);
  font-family: var(--secondary-font);
}

.modal_footer {
  background-color: #fb9912;
  text-align: center;
  height: "30px";
  padding: 8px;
  display: flex;
  color: #fff;
  justify-content: space-between;
}
