.arrow_left {
  width: 95px;
  height: 40px;
  line-height: 1px;
  border: 1px solid var(--primary-color);
  margin-right: 20px;
  color: var(--primary-color);
}

.arrow_right {
  width: 95px;
  cursor: pointer;
  height: 40px;
  line-height: 1px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.arrow_right:hover {
  background-color: #d3cfcf;
  cursor: pointer;
}

.arrow_left:hover {
  background-color: #d3cfcf;
  cursor: pointer;
}
.testimonoal_heading_container {
  display: flex;
  cursor: pointer;
  margin-top: 50px;
  justify-content: space-between;
  padding-left: 120px;
  padding-right: 82px;
}

.testimonial_container_swiper {
  display: flex;
}

.swiper_container {
  margin-top: 60px;
}

.Testi_monials_subTitle {
  font-family: var(--secondary-font);
  font-weight: 800;
  color: #051a3d;
  margin-top: 10px;
  font-size: 55px;
}
.Testi_monials_heading {
  font-size: 22px;
  font-weight: 500;
  font-family: var(--primary-font);
  color: var(--primary-color);
}

.testimonial_container {
  width: 100%;
  background-color: #f7f7f7;
  padding: 30px 0px 30px 0px;
}
.heading_righ_column {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 870px) and (min-width: 700px) {
  .heading_righ_column {
    width: 290px;
    justify-content: end;
  }
  .arrow_left,
  .arrow_right {
    width: 80px;
  }
}
@media screen and (max-width: 700px) {
  .Testi_monials_subTitle {
    font-size: 28px;
  }
  .testimonoal_heading_container {
    padding: 0px 20px 0px 20px;
  }
}
@media screen and (max-width: 500px) {
  .arrow_left,
  .arrow_right {
    width: 50px;
    height: 25px;
  }
  .arrow-image {
    width: 20px;
  }
  .heading_righ_column {
    width: 150px;
    justify-content: end;
  }
  /* .swiper_container {
    padding-left: 20px;
  } */
}
@media screen and (max-width: 375px) {
  .Testi_monials_heading {
    font-size: 19px;
  }
}
.testimonial_card {
  margin: auto;
}
