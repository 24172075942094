.sec_2_text_3 {
  font-size: 13px;
}
.heading_container_popular {
  margin: 4% 0% 4% 0%;
  display: flex;
  justify-content: space-between;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 830px) {
  /* .heading_column {
    width: 100%;
  } */
  .sec_2_text_2 {
    font-size: 7vw;
  }
}
