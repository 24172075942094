.footer_container {
  display: flex;
  flex-flow: row wrap;
  padding: 4% 4% 1% 10%;
  color: #fff;
  background-color: #002258;
  margin-top: 100px;
}

.footer_container > * {
  flex: 1 100%;
}

.l-footer {
  margin-right: 1.25em;
  margin-bottom: 2em;
}
.l-footer img {
  width: 120px;
  position: relative;
  bottom: 125px;
  /* left: 25px; */
}
.l-footer div {
  width: 10px;
  height: 35px;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 1.6em;
}

.footer a {
  text-decoration: none;
}

.r-footer {
  display: flex;
  flex-flow: row wrap;
}

.r-footer > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.box a {
  color: #999;
  font-size: 15px;
}
.box iframe {
  width: 250px;
  height: 200px;
  border: 0;
}
.b-footer {
  text-align: center;
  color: #999;
  background-color: #0a2e67;
}

.l-footer p {
  text-align: justify;
  color: #999;
}
.footer_header {
  font-size: 25px;
  font-family: var(--secondary-font);
  font-weight: 800;
  padding: 10px 0px 10px 0px;
}
.h-box p {
  font-size: 15px;
  color: #999;
}
.h-box li {
  color: #999;
  font-size: 15px;
  line-height: 2em;
}
@media screen and (max-width: 1050px) {
  .l-footer div {
    /* width: 10px; */
    height: 50px;
  }
  .l-footer img {
    width: 120px;
    position: relative;
    bottom: 115px;
    /* left: 25px; */
  }
}
@media screen and (min-width: 600px) and (max-width: 980px) {
  .footer_container {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .l-footer img {
    width: 120px;
    position: relative;
    bottom: 90px;
    /* left: 25px; */
  }
}
@media screen and (min-width: 600px) {
  .r-footer > * {
    flex: 1;
  }

  .features {
    flex-grow: 1;
  }

  .l-footer {
    flex: 1 0px;
  }

  .r-footer {
    flex: 2 0px;
  }
}
