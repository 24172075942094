.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: auto; /* Should be removed. Only for demonstration */
}
.Photo_Section {
  margin: auto;
}

/* Clear floats after the columns */
.Photo_Section:after {
  content: "";
  display: table;
  clear: both;
}
.column_img_1 {
  width: 100%;
  height: 300px;
}
.column_img_2 {
  width: 100%;
  height: 400px;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
.photo_quote {
  height: 50px;
  width: 50px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 1px rgb(161, 161, 161);
}
.photo_quote i {
  color: var(--primary-color);
}
.Photo_text_container {
  position: relative;
  bottom: 30px;
  padding: 0 15px 15px;
  box-shadow: 0px 5px 6px 1px rgb(212, 212, 212);
}
.Photo_Section_header {
  font-size: 20px;
  font-weight: 900;
  font-family: var(--primary-font);
  color: var(--sacondary-color);
}
.Photo_Section_text {
  font-size: 13px;
  font-family: var(--primary-font);
}

/* media css */
@media screen and (max-width: 1050px) {
  .column {
    width: 50%;
  }
}
@media screen and (max-width: 660px) {
  .column {
    width: 100%;
  }
}
