.testimonial_card {
  width: 280px;
  background-color: white;
  padding: 25px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial_img img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.quote_box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 45px;
  left: 88px;
  border: 3px solid white;
}

.Image_seter {
  height: 20px !important;
  width: 20px !important;
}

.testimonial_name .name {
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  margin-top: 8px;
  font-family: var(--primary-font);
  color: var(--sacondary-color);
}
.testimonial_name .review {
  font-size: 15px;
  text-align: center;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: auto;
  margin-top: 10%;
}

.testimonial_text p {
  text-align: center;
  font-size: 16px;
  font-family: var(--primary-font);
}
@media screen and (max-width: 940px) and (min-width: 890px) {
  .testimonial_card {
    width: 260px;
  }
}
