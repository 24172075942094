.stars_box {
  display: flex;
}
.heading_container_Package_details {
  margin: 4% 0% 4% 0%;
  display: flex;
  justify-content: space-between;
}
.heading_righ_column_package_details {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .heading_container_Package_details {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
}
