/* car selection section */
.car_section_column {
  float: left;
  width: 30%;
  height: auto; /* Should be removed. Only for demonstration */
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 15px 15px 15px 15px;
}

/* Clear floats after the columns */
.car_section_img_text {
  padding: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.car_section_row {
  margin-top: 100px;
  display: flex;
  /* justify-content: space-between; */

  /* overflow-wrap : break-word */
  flex-flow: wrap;
}
/* .car_section_row:after {
  content: "";
  display: table;
  clear: both;
} */
.car_section_text {
  margin: 5px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.car_section_img_text img {
  width: 100%;
  height: 230px;
  border: 1px solid #a7a7a7;
}
.car_section_text p {
  font-size: 24px;
  font-family: var(--primary-font);
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--sacondary-color);
  margin-top: 10px;
}
.car_section_text div {
  font-size: 18px;
  font-family: var(--primary-font);
  color: var(--sacondary-color);
  font-weight: 600;
}
.car_section_text span {
  color: var(--primary-color);
}
.car_inquiry_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  padding: 5px 10px 5px 26px;
}
.car_inquiry_box span {
  color: white;
  font-family: var(--primary-font);
  font-weight: 700;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 1150px) {
  .car_section_text p {
    font-size: 17px;
  }
}
@media screen and (max-width: 860px) {
  .car_section_column {
    width: 48%;
  }
}
@media screen and (max-width: 1150px) {
  .car_section_text p {
    font-size: 17px;
  }
}
@media screen and (max-width: 700px) {
  .car_section_column {
    width: 70%;
    /* margin: auto; */
    margin: 15px auto;
  }
  .car_section_row {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .car_section_column {
    width: 100%;
  }
}
