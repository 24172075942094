.view_detail {
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-color);
}
.upcoming_tips_container {
  width: 100%;
  background-color: #f7f7f7;
  padding: 3% 0;
}
.Our_Special_Packages {
  width: 100%;
  padding: 50px 0;
}
.heading_container_upcoming {
  margin: 4% 0% 4% 0%;
  display: flex;
  justify-content: space-between;
}
.heading_text_1 {
  font-size: 15px;
  font-family: var(--primary-font);
  color: var(--primary-color);
}
.heading_text_2 {
  margin-top: 5px;
  font-size: 40px;
  font-weight: 700;
  font-family: var(--primary-font);
  color: var(--sacondary-color);
}

@media screen and (max-width: 830px) {
  .heading_text_2 {
    font-size: 21px;
  }
  .heading_text_1 {
    font-size: 14px;
  }
}

