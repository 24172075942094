.hotel_card_container_column {
  /* margin: 0px 10px 0px 10px; */
  width: 340px;
  height: auto;
}
.Hotel_card_container_img {
  width: 100%;
  height: 300px;
}
.hotel_card_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.hotel_card_container:after {
  display: table;
  clear: both;
}
.hotel_Text_name {
  font-size: 18px;
  font-weight: 600;
}
.hotelText_div {
  /* height: 60px; */
  text-align: start;
  display: flex;
  background: #fff;
  /* padding-left: 21px;*/
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 19px;
}

.price_box_container_hotel {
  display: flex !important;
  height: 1px !important;
  justify-content: end !important;
}
.view_more_text {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
@media screen and (max-width: 1290px) {
  .hotel_card_container_column {
    width: 320px;
  }
}
@media screen and (max-width: 1200px) {
  .hotel_card_container_column {
    width: 400px;
  }
}
@media screen and (max-width: 1050px) {
  .hotel_card_container_column {
    width: 350px;
  }
}
@media screen and (max-width: 890px) {
  .hotel_card_container_column {
    width: 300px;
  }
}
@media screen and (max-width: 750px) {
  .hotel_card_container_column {
    width: 350px;
    margin-top: 10px;
  }
  .hotel_card_container {
    justify-content: center;
  }
}
@media screen and (max-width: 420px) {
  .hotel_card_container_column {
    width: 100%;
  }
  .hotel_card_container {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1218px) {
  .hotel_card_container_column {
    /* margin: 0px 20px 0px 20px; */
  }
}
