.login-box {
  width: 25em;
  height: 32em;
  background: rgba(239, 218, 241, 0.438);
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 3em 3em;
  border-radius: 10%;
}

.login-image {
  width: 7em;
  height: 7em;
  border-radius: 50%;
  position: absolute;
  top: -3.7em;
  left: calc(50% - 3.7em);
}

h3 {
  margin: 0 auto;
  padding: 0.5em 0;
  text-align: center;
  font-size: 2.5rem;
}

.login-box p {
  margin: 0;
  padding: 1em 0;
  font-weight: bold;
}

.login-box input {
  width: 100%;
  margin-bottom: 2em;
}

.login-box input[type="email"],
input[type="password"] {
  border: none;
  border-bottom: 1.2px solid rgb(141, 14, 130);
  background: transparent;
  outline: none;
  height: 2em;
  color: rgb(141, 14, 130);
  font-size: 16px;
}

.login-box input[type="button"] {
  border: none;
  outline: none;
  height: 2.5em;
  background: rgb(141, 14, 130);
  color: white;
  font-size: 1em;
  border-radius: 1.5rem;
}

.login-box input[type="submit"]:hover {
  cursor: pointer;
  background: rgb(221, 75, 209);
  color: white;
}

.login-box a {
  text-decoration: none;
  font-size: 0.75em;
  line-height: 1.7em;
  color: rgb(53, 51, 58);
  display: flex;
  flex-direction: vertical;
}

.login-box a:hover {
  color: rgb(141, 14, 130);
}
