.sidebar {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: black;
  background-color: #051a3d;
  height: auto;
  line-height: 35px;
}

.main_sidebar_wrapper {
  display: flex;
}

.sidebar li {
  padding: 1em;
  color: rgb(240, 248, 255);
  width: 300px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
}
.sidebar-active {
  background-color: #fcb147;
  color: black;
}