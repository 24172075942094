.top_container {
  position: absolute;
  right: 0;
  background: url(../../assets/image/home_backgroundjpg.jpg) center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 75%;
  height: 360px;
  z-index: -1;
}
.Package_Detail_top_container {
  position: absolute;
  right: 0;
  background: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 75%;
  height: 360px;
  z-index: -1;
}
.top_container:after,
.top_container:before {
  content: "";
  position: absolute;
  display: block;
  width: inherit;
  height: 360px;
  left: -75%;
}
.top_container:after {
  background: inherit;
  transform: scaleX(-1);
}
.top_container:before {
  z-index: 1;
  background: linear-gradient(
    to left,
    rgba(114, 114, 114, 0.281),
    rgb(3, 3, 3)
  );
}
.main_container {
  height: 360px;
  display: flex;
  align-items: center;
  /* padding-left: 8%; */
}
.container_content {
  font-size: 55px;
  font-family: var(--secondary-font);
  font-weight: 900;
  color: white;
}
.read_more {
  padding: 7px 15px;
  background-color: #fb9912;
  margin-top: 25px;
  color: white;
  border: 0;
}

.top_container_about {
  width: 100%;
  display: flex;
  justify-content: center;
}
.top_container_about p {
  font-size: 50px;
  font-family: var(--secondary-font);
  font-weight: 900;
  color: white;
}
/*  media css  */

@media (max-width: 900px) {
  .container_content {
    font-size: 25px;
  }
  .top_container {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .container_content {
    font-size: 30px;
  }
}
