@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
  font-family: var(--primary-font);
}
:root {
  --primary-color: #fb9912;
  --sacondary-color: #002258;
  --white-color: #fff;
  --primary-font: "Poppins", sans-serif;
  --secondary-font: "Playfair Display", serif;
}

.center_container {
  width: 80%;
  margin: auto;
}

.whats-app {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0px 2px -1px #999;
  right: 15px;
  z-index: 100;
}

@media screen and (max-width: 1250px) {
  .center_container {
    width: 80%;
    margin: auto;
  }
}
@media screen and (max-width: 770px) {
  .center_container {
    width: 84%;
    margin: auto;
  }
}
@media screen and (max-width: 380px) {
  .center_container {
    width: 90%;
    margin: auto;
  }
}

.cursor-point {
  cursor: pointer;
}
/*  admin css */

.admin_main_section {
  display: flex;
}
.heading_div {
  text-align: center;
}
.admin_start {
  width: 75%;
  margin-left: 20px;
}

.submit_Btn {
  background-color: #27d564;
  color: #fff;
  border: 1px solid #fff;
  width: 130px;
  height: 36px;
  border-radius: 8px;
}
