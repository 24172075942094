.Package_title_image {
  width: auto;
  height: 360px;
}

.sec_2_text_3 {
  font-size: 13px;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 830px) {
  .sec_2_text_2 {
    font-size: 7vw;
  }
}

.fa-star {
  margin: 1px 2px;
}
.fa-star:before {
  color: var(--primary-color);
}
#start_border:before {
  color: white;
  text-shadow: 0px 0px 2px var(--primary-color),
    0px 0px 3px var(--primary-color);
}
.package_price {
  color: var(--primary-color);
  font-size: 22px;
  font-weight: 700;
}
#package_person {
  font-family: var(--primary-font);
  font-size: 15px;
  color: gray;
}
/* Second Section */
/* Video And Photo section */
.iframe_package {
  width: 100%;
  height: 100%;
}
.vi_img_box_column {
  float: left;
  width: 48%;
  height: 430px;
}
.vi_img_box {
  display: flex;
  justify-content: space-between;
}

/* Clear floats after the columns */
.vi_img_box:after {
  content: "";
  display: table;
  clear: both;
}
.video_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 429px;
  border: 1px solid rgb(173, 173, 173);
}
.video_box iframe {
  width: 100%;
  height: 100%;
}
.image_box_1 img {
  width: 100%;
  height: 265px;
}
.image_box_2 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  height: 133px;
  column-gap: 20px;
}
.image_box_2 div {
  width: 32%;
}
.image_box_2 div img {
  width: 100%;
  height: 100%;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .vi_img_box_column {
    width: 100%;
  }
  .vi_img_box {
    flex-direction: column;
  }
  .image_box {
    margin-top: 10px;
  }
}
.sec_2_text_3 {
  font-size: 13px;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 830px) {
  /* .heading_column {
    width: 100%;
  } */
  .sec_2_text_2 {
    font-size: 7vw;
  }
}
.read_more {
  padding: 7px 15px;
  background-color: #fb9912;

  color: white;
  border: 0;
}
.box_text {
  text-align: justify;
  font-size: 14px;
}

/* table section */
.table_container_column {
  float: left;
  width: 48%;
  height: auto; /* Should be removed. Only for demonstration */
  margin-bottom: 5px;
}
#table_container_column_right {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 7px;
  padding: 1% 10px 0px 10px;
}
.table_container {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

/* Clear floats after the columns */
.table_container:after {
  content: "";
  display: tablecenter;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 750px) {
  .table_container_column {
    width: 100%;
  }
  .table_container {
    margin-top: 5%;
    display: block;
    justify-content: space-between;
  }
}
.package_table,
.package_table th,
.package_table td {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-spacing: 0px;
}
.package_table {
  height: 433px;
}
.package_table td {
  text-align: center;
  opacity: 50%;
  font-weight: 200;
  font-size: 24px;
}

.package_table tr th,
.package_table tr td {
  line-height: 50px;
}
.collapsible {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 10px 18px 10px 18px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
  outline: none;
  font-size: 15px;
  margin: 2px 0px 2px 0px;
}

.activee,
.collapsible:hover {
  background-color: rgb(231, 231, 231);
}

.activee:after {
  content: "\2212";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 13px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
#table_container_column_right h2 {
  color: var(--sacondary-color);
  font-family: var(--primary-font);
  margin: 5px 0px;
}

/* table section */
/* Review Section */
.review_container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 35px;
}
.review_img img {
  border-radius: 50%;
}
.review_content {
  padding-left: 20px;
}
.review_content p {
  font-size: 13px;
}
.Give_Review {
  color: var(--primary-color);
  margin-top: 25px;
  cursor: pointer;
  margin-left: 80px;
}
/* Modal CSS */
/* star Css */
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
/* star Css */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 8%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.644); /* Black w/ opacity */
}

/* Modal Content */
.image-upload > input {
  display: none;
}
.image-upload img {
  width: 80px;
  margin-top: 20px;
}
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.507), 0 6px 20px 0 rgb(0, 0, 0);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: rgb(0, 0, 0);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  height: 35px;
  color: rgb(0, 0, 0);
}
.modal_body p {
  font-size: 28px;
  font-weight: 900;
  font-family: var(--secondary-font);
  color: var(--sacondary-color);
}
.modal_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.modal_form form {
  margin: auto;
  width: 70%;
  padding: 20px 0px 40px 0px;
}
.modal_form form input {
  margin: 7px 0px 7px 0px;
  width: 100%;
  height: 35px;
  padding-left: 10px;
}
.modal_form textarea {
  padding: 7px 0px 0px 10px;
  margin: 7px 0px 7px 0px;
  width: 100%;
}
.review_submit {
  width: 100%;
  margin-top: 10px;
}
@media (max-width: 880px) {
  .modal-content {
    width: 70%;
  }
  .modal {
    padding-top: 150px;
  }
}
@media (max-width: 550px) {
  .modal-content {
    width: 90%;
  }
  .modal {
    padding-top: 150px;
  }
  .modal_body p {
    font-size: 19px;
  }
}
/* Modal CSS */
/* Review Section */

/* footer Section */
/* .footer_container {
  display: flex;
  flex-flow: row wrap;
  padding: 4% 4% 1% 10%;
  color: #fff;
  background-color: #002258;
  margin-top: 100px;
} */

.footer_container > * {
  flex: 1 100%;
}

.l-footer {
  margin-right: 1.25em;
  margin-bottom: 2em;
}
.l-footer img {
  width: 120px;
  position: relative;
  bottom: 125px;
  /* left: 25px; */
}
.l-footer div {
  width: 10px;
  height: 35px;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
}

.r-footer {
  display: flex;
  flex-flow: row wrap;
}

.r-footer > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.box iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
.b-footer {
  align-items: center;
  color: rgb(187, 187, 187);
  background-color: #0a2e67;
  height: 40px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1050px) {
  .l-footer div {
    /* width: 10px; */
    height: 50px;
  }
  .l-footer img {
    width: 120px;
    position: relative;
    bottom: 115px;
    /* left: 25px; */
  }
}
@media screen and (max-width: 600px) {
  .l-footer img {
    width: 120px;
    position: relative;
    bottom: 90px;
    /* left: 25px; */
  }
  .footer_container {
    margin-top: 30%;
  }
}
@media screen and (min-width: 600px) {
  .r-footer > * {
    flex: 1;
  }

  .features {
    flex-grow: 1;
  }

  .l-footer {
    flex: 1 0px;
  }

  .r-footer {
    flex: 2 0px;
  }
}

/* footer Section */
