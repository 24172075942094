/* Upper Navbar Css start */
.Upper_Nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 6% 5px 8%;
  font-family: var(--primary-font);
  background-color: var(--sacondary-color);
  color: #fff;
  font-size: 10px;
}
.Upper_Nav p i {
  margin-right: 5px;
}
/* Upper Navbar Css  End*/

/* .Navbar css */
.Navbar {
  display: flex;
  height: 65px;
  width: 100%;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding: 0 5% 0 100px;
  flex-wrap: wrap;
}

.logo-img {
  width: 90px;
  position: absolute;
  top: 30px;
}
.Navbar ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.Navbar ul li {
  margin: 0 3px;
}
.Navbar ul li a {
  color: #051a3d;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 6px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  font-family: var(--primary-font);
}
.Navbar ul li a.active,
.Navbar ul li a:hover {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.Navbar .menu-btn i {
  color: var(--secondary-color);
  font-size: 22px;
  cursor: pointer;
  display: none;
}
input[type="checkbox"] {
  display: none;
}
.stickynav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 101;
}

@media (max-width: 1150px) {
  .Navbar {
    padding: 0 40px 0 50px;
  }
}
@media (max-width: 1090px) {
  /* Upper Navbar css */
  .logo-img {
    width: 80px;
    position: absolute;
    top: 27px;
    left: 15px;
    z-index: 100;
  }
  .Navbar .menu-btn i {
    display: block;
  }
  #click:checked ~ .menu-btn i:before {
    content: "\f00d";
  }
  .Navbar ul {
    position: fixed;
    top: 91px;
    left: -100%;
    background: rgb(204, 204, 204);
    height: 100vh;
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }
  #click:checked ~ ul {
    left: 0;
  }
  .Navbar ul li {
    width: 100%;
    margin: 15px 0;
  }
  .Navbar ul li a {
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  #click:checked ~ ul li a {
    margin-left: 0px;
  }
  .Navbar ul li a.active,
  .Navbar ul li a:hover {
    background: none;
    color: var(--primary-color);
  }
}
@media screen and (max-width: 1050px) {
  .Upper_Nav {
    padding: 5px 20px;
  }
}
