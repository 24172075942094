.special_righ_column {
  display: flex;
  align-items: center;
}
.special_righ_column p {
  font-size: 13px;
  color: var(--sacondary-color);
  font-weight: 600;
  width: 100%;
  padding-left: 30px;
}
@media screen and (max-width: 1015px) {
  .special_righ_column {
    width: 100%;
    padding-left: 1%;
    margin-left: -18px;
    margin-top: 9px;
    margin-bottom: 8px;
  }
  .special_left_column {
    width: 100%;
  }
}
.special_righ_column .domestic_p_color {
  color: var(--primary-color);
}
.view_all_btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.heading_container {
  margin: 4% 0% 2% 0%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .special_righ_column {
    margin-top: 15px;
  }
  .heading_container {
    flex-direction: column;
  }
}
