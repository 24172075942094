.card_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.card_container_column {
  /* float: left; */
  padding: 10px;
  width: 340px;
  height: auto; /* Should be removed. Only for demonstration */
}
.price_box_container_Package {
  display: flex !important;
  height: 1px !important;
  flex-flow: row-reverse;
}
.price_box {
  width: 121px;
  height: 52px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
  box-shadow: 6px -3px 20px -7px #e2e2e2;
  bottom: 58px;
  /* z-index: 1; */
}
.price_box p {
  font-family: var(--primary-font);
  color: var(--primary-color);
  font-weight: 900;
  font-size: 16px;
}
/* Clear floats after the columns */
.card_container:after {
  display: table;
  clear: both;
}
.card_container_img {
  width: 100%;
  height: 300px;
}
.text_container {
  background-color: white;
  position: relative;
  bottom: 7px;
}
.text_container_Package_card {
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0px 8px 10px 1px rgb(232, 232, 232);
  position: relative;
  bottom: 7px;
}
.box_text_1 {
  /* padding: 13px 20px 13px 25px; */
  font-size: 15px;
  font-weight: 700;
  color: var(--sacondary-color);
}
.box_text_2 {
  margin: 5px 0;
  font-size: 10px;
  font-weight: 100;
  color: var(--sacondary-color);
}
.fa-star {
  margin: 1px 2px;
}
.fa-star:before {
  color: var(--primary-color);
}
#start_border:before {
  color: white;
  text-shadow: 0px 0px 2px var(--primary-color),
    0px 0px 3px var(--primary-color);
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1290px) {
  .card_container {
    flex-flow: wrap;
  }
  .card_container_column {
    width: 320px;
  }
}
@media screen and (max-width: 1200px) {
  .card_container_column {
    width: 400px;
  }
}
@media screen and (max-width: 1050px) {
  .card_container_column {
    width: 350px;
  }
}

@media screen and (max-width: 890px) {
  .card_container_column {
    width: 300px;
  }
}
@media screen and (max-width: 750px) {
  .card_container_column {
    width: 350px;
    margin-top: 10px;
  }
  .card_container {
    justify-content: center;
  }
}
@media screen and (max-width: 420px) {
  .card_container_column {
    width: 100%;
  }
  .card_container {
    margin-bottom: 20px;
  }
}
