.SubCate_main {
  display: flex;
  column-gap: 21px;
  align-items: flex-end;
}
.table_Icon_admin {
  display: flex;
  column-gap: 12px;
  justify-content: center;
  align-items: center;
}

.text_center {
  text-align: center;
}

.Data_table_main {
  margin-top: 20px;
}
