.USER_PopHeader {
  font-weight: 800;
  font-size: 24px;
  color: var(sacondary-color);
  font-family: var(--secondary-font);
}

.Center_userReview_Box {
  text-align: center;
  margin-top: 20px;
}

.User_picture {
  margin-top: 25px;
  cursor: pointer;
}

.hidden_input {
  display: none;
  opacity: 0;
}

.User_Profile_Picture {
  height: 110px;
  width: 110px;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  border: 2px dashed #fb9912;
}

.submit_button {
  width: 100%;
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  height: 40px;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  font-family: var(--primary-font);
}
.modal_grid {
  display: grid;
  gap: 20px;
  padding: 0px 19px;
}
