.hotel_card_container {
  flex-flow: wrap;
  margin-top: 100px;
  gap: 50px 0px;
  /* margin-left: 100px;
  margin-right: 100px;
  flex-wrap: wrap;
  display: flex;
  margin-top: 100px;
  column-gap: 70px;
  gap: 50px 0px; */
}
.hotel_center_container {
  width: 100%;
}
