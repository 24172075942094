.top_container_about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top_container_about .about {
  font-size: 40px;
  font-family: var(--secondary-font);
  font-weight: 900;
}

/* Home Page Css */
@media (max-width: 900px) {
  .top_container {
    width: 100%;
  }
}

.contact-input,
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type="submit"] {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.contact_container {
  border-radius: 5px;
  /* padding: 25%px; */
  width: 75%;
  margin: auto;
  margin-top: 4%;
}

.cols-form {
  float: left;
  width: 100%;
  margin-top: 13px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
#subject {
  height: 200px;
}
.submit_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit_btn input {
  margin: auto;
}
/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
