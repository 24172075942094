.section_2 {
  margin: auto;
  margin-top: 5%;
  margin-bottom: 1%;
}
.section_2_column {
  float: left;
  padding: 10px;
  min-height: 200px; /* Should be removed. Only for demonstration */
  display: flex;
  justify-content: center;
  align-items: center;
}
.left_column {
  width: 40%;
}
.righ_column {
  background-color: #f7f7f7;
  width: 60%;
  padding: 2% 4%;
}
/* Clear floats after the columns */
.section_2:after {
  content: "";
  display: table;
  clear: both;
}
.sec_2_text_1 {
  font-size: 15px;
  font-family: var(--primary-font);
  color: var(--primary-color);
}
.sec_2_text_2 {
  margin-top: 5px;
  font-size: 35px;
  font-weight: 900;
  font-family: var(--secondary-font);
  color: var(--sacondary-color);
}
.sec_2_text_3 {
  font-size: 13px;
}

/* media css */
@media screen and (max-width: 830px) {
  .section_2_column {
    width: 100%;
  }
  .sec_2_text_2 {
    font-size: 7vw;
  }
}
